import React from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import PasswordStrengthBar from 'react-password-strength-bar'
import AlertDynamic from '../../components/AlertDynamic'
import { unauthNavTo } from '../../components/navigation'
import Spinner from '../../components/spinner'
import { useLoginMutation, useSignUpWithToken } from '../../utils/api'

const Form1SetPassword = (props) => {
  const queryParams = new URLSearchParams(window.location.search)
  const isMember = queryParams.get('member')
  const [totalPage] = React.useState(isMember ? 2 : 5)
  const [isPasswordFormatRight, setIsPasswordFormatRight] =
    React.useState(true)
  const [isPasswordSameCheck, setIsPasswordSameCheck] = React.useState(true)
  const [passwordState, setPasswordState] = React.useState({
    type: 'password',
    show: false
  })
  const [confirmPasswordState, setConfirmPasswordState] = React.useState({
    type: 'password',
    show: false
  })
  // update keys for dynamic alerts
  const [update, setUpdate] = React.useState(0)
  const { handleChange, values } = props

  const signUpWithToken = useSignUpWithToken()
  const loginMutation = useLoginMutation()

  const goContinue = (e) => {
    const form = e.currentTarget.form
    if (form.checkValidity() === false) {
      e.stopPropagation()
    } else if (form.checkValidity() === true) {
      e.preventDefault()
      setUpdate(update + 1)
      setIsPasswordFormatRight(true)
      setIsPasswordSameCheck(true)
      checkStrongPassword()
    }
  }

  // check if the password is strong
  const checkStrongPassword = () => {
    const strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\?\=\.!@#\$%\^&\*])(?=.{8,})/

    const isPasswordSame = values.password === values.confirmPassword
    if (
      strongRegex.test(values.password) &&
      strongRegex.test(values.confirmPassword)
    ) {
      setIsPasswordFormatRight(true)
      if (!isPasswordSame) {
        setIsPasswordSameCheck(false)
      } else {
        submitRegisterForm()
      }
    } else {
      setIsPasswordFormatRight(false)
    }
  }
  const submitRegisterForm = () => {
    const credentials = { ...values }
    delete credentials.token
    delete credentials.confirmPassword
    delete credentials.isMember
    delete credentials.isParticipant
    signUpWithToken.mutate({
      credentials: credentials,
      token: values.token,
      member: values.isMember,
      participant: values.isParticipant
    })
  }

  React.useEffect(() => {
    if (signUpWithToken.isSuccess) {
      doLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpWithToken.isSuccess])

  const doLogin = () => {
    const loginCredentials = {
      username: values.email,
      password: values.password
    }
    loginMutation.mutate(loginCredentials)
  }

  React.useEffect(() => {
    if (loginMutation.isSuccess) {
      if (isMember) {
        // if member of a company sign up, route to dashboard
        unauthNavTo.dashboardPage()
      } else {
        props.nextStep()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginMutation.isSuccess])

  const clickEyeHandler = (e) => {
    e.preventDefault()
    const InputElement = e.currentTarget.parentElement.firstChild
    setPasswordState({
      type: InputElement.type === 'password' ? 'text' : 'password',
      show: !passwordState.show
    })
  }
  const clickConfirmPasswordEyeHandler = (e) => {
    e.preventDefault()
    const InputElement = e.currentTarget.parentElement.firstChild
    setConfirmPasswordState({
      type: InputElement.type === 'password' ? 'text' : 'password',
      show: !confirmPasswordState.show
    })
  }

  const goBack = (e) => {
    e.preventDefault()
    loginMutation.reset()
    props.prevStep()
  }

  return (
    <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-6">
      {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}
      <div className="d-flex justify-content-between">
        <h4 className="mb-3 font-weight-bold inter-font-content">
          Set your password{' '}
        </h4>
        <h4 className="mb-3 font-weight-bold inter-font-content">2/{totalPage}</h4>
      </div>
      <p
        style={{
          fontSize: 12 + 'px!important',
          color: 'grey!important'
        }}
      >
        Choose a 8 or more character password that contains letters (uppercase
        and lowercase), numbers and a symbol.
      </p>
      {/* form */}
      <Form className="d-flex flex-column flex-grow-1 justify-content-between">
        <div>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <InputGroup className="mb-2 input-area-to-hide-eyes">
              <Form.Control
                type={passwordState.type}
                autoFocus={true}
                required
                value={values.password}
                onChange={handleChange('password')}
              />

              <button
                type="button"
                tabIndex={-1}
                onClick={clickEyeHandler}
                className="btn btn-outline-secondary form-inline-button"
              >
                <i
                  className={
                    passwordState.show ? 'fas fa-eye-slash' : 'fas fa-eye'
                  }
                ></i>
              </button>
            </InputGroup>
          </Form.Group>

          <PasswordStrengthBar
            password={values.password}
            minLength={8}
            barColors={['#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']}
            scoreWords={['Weak', 'Weak', 'Okay', 'Good', 'Strong']}
            shortScoreWord={
              values.password.length > 0 ? 'Your password is too short' : ''
            }
          />

          <Form.Group>
            <Form.Label>Confirm password</Form.Label>
            <InputGroup className="mb-2 input-area-to-hide-eyes">
              <Form.Control
                type={confirmPasswordState.type}
                required
                value={values.confirmPassword}
                onChange={handleChange('confirmPassword')}
              />
              <button
                type="button"
                tabIndex={-1}
                onClick={clickConfirmPasswordEyeHandler}
                className="btn btn-outline-secondary form-inline-button"
              >
                <i
                  className={
                    confirmPasswordState.show
                      ? 'fas fa-eye-slash'
                      : 'fas fa-eye'
                  }
                ></i>
              </button>
            </InputGroup>
            {/*
            {values.confirmPassword
              ? (
                  values.confirmPassword === values.password
                    ? (
                <p style={{ color: '#00BA79' }}>your password is same</p>
                      )
                    : (
                <p style={{ color: '#9A103E' }}>your password is different</p>
                      )
                )
              : (
                  ''
                )}
                */}
          </Form.Group>
        </div>

        <Form.Group className="mb-0 d-flex justify-content-between">
          <Button
            variant="outline-primary"
            type="button"
            style={{ width: 100 + 'px' }}
            onClick={(e) => goBack(e)}
          >
            <span>Back</span>
          </Button>
          <Button
            className="mt-0 "
            type="submit"
            disabled={
              loginMutation.isLoading ||
              values.email.length === 0 ||
              values.password.length === 0
            }
            onClick={(e) => goContinue(e)}
          >
            {loginMutation.isLoading || signUpWithToken.isLoading
              ? (
              <Spinner />
                )
              : (
              <span>Continue</span>
                )}
          </Button>
        </Form.Group>

        {isPasswordFormatRight
          ? (
              ''
            )
          : (

          <AlertDynamic key={update} msg= {
            'Password must be 8 or more characters and contain letters (uppercase and lowercase), numbers and a symbol (?=.!@#$%^&*).'
          }></AlertDynamic>
            )}
        {isPasswordSameCheck
          ? (
              ''
            )
          : (

          <AlertDynamic key={update} msg= {'Passwords do not match.'}></AlertDynamic>
            )}

        {signUpWithToken.isError && (

          <AlertDynamic key={update} msg={signUpWithToken.error.response.data.message}></AlertDynamic>
        )}
      </Form>
    </div>
  )
}

export default Form1SetPassword
